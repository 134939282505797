import React from "react";
import LogoSVG from "../../assets/LOGO.svg"

const Header = () => {
  return (
    <header>
      <LogoSVG />
      <h2><span className="smaller">Prof. Dr.</span><br/>Nurperi Gazioğlu</h2>
    </header>
  )
}

export default Header;